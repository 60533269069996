import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";

//Rotas públicas e privadas
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";

import Login from "../pages/Login";
import Register from "../pages/Register";
import QuickTournaments from "../pages/QuickTournaments";
import BeManager from "../pages/BeManager";
import Manager from "../pages/Manager";
import PlayersTraining from "../pages/PlayersTraining";
import Home from "../pages/Home";
import Account from "../pages/Account";
import ChangePassword from "../pages/Account/ChangePassword";
import Tickets from "../pages/Tickets";
import Ticket from "../pages/Ticket";
import Admin from "../pages/Admin";
import User from "../pages/Admin/User";
import Tournaments from "../pages/Tournaments";
import Page404 from "../pages/Page404";
import Tournament from "../pages/Tournament";
import NewTicket from "../pages/NewTicket";
import Fidelity from "../pages/Fidelity";
import ApprovingManager from "../pages/ApprovingManager";
import ApprovingCupCopag from "../pages/ApprovingCupCopag";
import ResetPassword from "../pages/ResetPassword";
import EditTournament from "../pages/Tournament/EditTournament";
import EditLeague from "../pages/Manager/EditLeague";
import EditingTexts from "../pages/EditingTexts";
import QuizPageAdmin from "../pages/QuizPageAdmin";
import CreateQuiz from "../pages/QuizPageAdmin/CreateQuiz";
import EditQuiz from "../pages/QuizPageAdmin/EditQuiz/index";
import QuizPage from "../pages/QuizPage";
import AnswerQuiz from "../pages/QuizPage/AnswerQuiz";
import AnsweredQuiz from "../pages/QuizPageAdmin/AnsweredQuiz";
import ReportQuiz from "../pages/QuizPageAdmin/ReportQuiz";
import ManagerBadgesForm from "../pages/Admin/ManagerBadgesForm";

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/">
                    <Redirect to={STYLEGUIDES.route} />
                </Route>

                <Route path="*" component={Page404} /> */}
        {/* <Route path={STYLEGUIDES.route} component={StyleGuides} /> */}

        <Route exact path="/">
          <Redirect to="/login" />
        </Route>

        <PublicRoute restricted={true} component={Login} path="/login" />
        <PublicRoute restricted={true} component={Register} path="/register" />

        <PublicRoute
          restricted={true}
          component={ResetPassword}
          path="/reset_password/:token"
        />

        <PublicRoute restricted={true} component={QuickTournaments} path="/torneios-rapidos" />

        <PrivateRoute component={BeManager} path="/seja-organizador" />
        <PrivateRoute component={PlayersTraining} path="/regras" />
        <PrivateRoute component={Manager} path="/organizador" />
        <PrivateRoute component={Account} path="/perfil" />
        <PrivateRoute component={ChangePassword} path="/trocar-senha" />
        <PrivateRoute component={Tournaments} path="/torneios" />
        {/* <PrivateRoute component={TournamentsWithoutRound} path="/torneios-ranking" /> */}
        <PrivateRoute component={Home} path="/home" />
        <PrivateRoute component={Tickets} path="/tickets" />
        <PrivateRoute component={Ticket} path="/ticket/:id" />
        <PrivateRoute component={NewTicket} path="/novo-ticket" />
        <PrivateRoute component={Fidelity} path="/programa-de-recompensa" />
        <PrivateRoute component={EditTournament} path="/editar-torneio/:id" />
        <PrivateRoute component={EditLeague} path="/editar-liga/:id" />

        <AdminRoute component={User} path="/administrador/usuario/:id" />
        <AdminRoute component={ManagerBadgesForm} path="/administrador/status-de-organizador/:id" />
        <AdminRoute component={ManagerBadgesForm} path="/administrador/status-de-organizador" />
        <AdminRoute component={Admin} path="/administrador" />
        <AdminRoute
          component={ApprovingManager}
          path="/solicitacao-organizador/:id"
        />
        <AdminRoute component={ApprovingCupCopag} path="/aprovar-torneio/:id" />
        <AdminRoute component={EditingTexts} path="/editar-textos/" />

        <AdminRoute component={QuizPageAdmin} path="/questionarios-admin" />
        <AdminRoute component={CreateQuiz} path="/cria-questionario" />
        <AdminRoute component={EditQuiz} path="/questionario-admin/:id" />

        <AdminRoute
          component={AnsweredQuiz}
          path="/questionario-resposta/:id"
        />

        <PublicRoute path="/pagina-nao-encontrada" component={Page404} />

        <PrivateRoute component={Tournament} path="/torneio/:tournamentId" />
        <PrivateRoute component={QuizPage} path="/questionarios" />
        <PrivateRoute component={AnswerQuiz} path="/questionario/:id" />
        <PrivateRoute
          component={ReportQuiz}
          path="/reportQuestionario/:quizId"
        />
        {/* <PrivateRoute component={TournamentWithoutRound} path="/torneio-ranking/:id" /> */}

        {/* <PrivateRoute component={Quiz} path="/questionario" /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
