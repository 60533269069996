import { useContext } from 'react';
import { AuthContext } from '../../../contexts/UserContext';
import { useLocation } from 'react-router-dom'
import {
    FaFacebookF,
    FaInstagram,
    FaTwitter,
    FaLinkedinIn,
    FaYoutube,
} from "react-icons/fa";

import './styles.scss';
import Logo from '../../Logo';
import home from '../../../assets/images/home.png';
import home_active from '../../../assets/images/home_active.png';
import profileSide from '../../../assets/images/profileSide.png';
import profileSide_active from '../../../assets/images/profileSide_active.png';
import fidelity from '../../../assets/images/fidelity.png';
import fidelity_active from '../../../assets/images/fidelity_active.png';
import tournament from '../../../assets/images/tournament.png';
import tournament_active from '../../../assets/images/tournament_active.png';
import manager from '../../../assets/images/manager.png';
import manager_active from '../../../assets/images/manager_active.png';
import ticket from '../../../assets/images/ticket.png';
import ticket_active from '../../../assets/images/ticket_active.png';
import quiz from '../../../assets/images/quiz.png';
import quiz_active from '../../../assets/images/quiz_active.png'; 
import { IMenuLink } from '../../../interfaces/GeneralInterfaces';
import LoyaltyPoints from '../../../assets/svg/LoyaltyPoints';
import { MenuLink } from '../../MenuSidebar';
import SocialList from '../../Socials/SocialList';
import api from "../../../services/api";
import { ToastContainer, toast } from "react-toastify";

interface menuProps {
    customClass: string;
}

const MenuSidebar = ({ customClass }: menuProps) => {
    const { userInfo, managerStatus } = useContext(AuthContext);

    const location = useLocation();

    const handleDownload = () => {
        var statusUsuario = managerStatus === "approved" ? 'organizador' : 'jogador';

        api.get('/manuais/downloadPdf/')
            .then(response => {
                
                if(response.data.filePath) {
                    const filePath = response.data.filePath;
                    const testePath = filePath.substring(filePath.lastIndexOf('/') + 1);
                    const fileUrl = window.location.origin + `/media/doc/` + testePath;
                    window.open(fileUrl, '_blank');
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                      });
                }
        
            })
            .catch(error => {
                console.error('Erro ao baixar o arquivo:', error);
            });
      };

    const menuLinks = [
        {
            route: '/home',
            name: 'Home',
            icon: <img src={home} alt="home"/>, 
            activeIcon: <img src={home_active} alt="home ativado"/>,
            isVisible: true
        },
        {
            route: '/perfil',
            name: 'Perfil',
            icon: <img src={profileSide} alt="perfil"/>, 
            activeIcon: <img src={profileSide_active} alt="perfil ativado"/>,
            isVisible: true
        },
        // {
        //     route: '/programa-de-recompensa',
        //     name: 'Programa de Recompensa',
        //     icon: <img src={fidelity} alt=""/>, 
        //     activeIcon: <img src={fidelity_active} alt=""/>,
        //     isVisible: true
        // },
        {
            route: '/torneios',
            name: 'Torneios',
            icon: <img src={tournament} alt="torneio"/>, 
            activeIcon: <img src={tournament_active} alt="torneio ativado"/>,
            isVisible: true
        },
        {
            route: '/ranking',
            name: 'Ranking',
            icon: <img src={fidelity} alt="ranking"/>, 
            activeIcon: <img src={fidelity_active} alt="ranking ativado"/>,
            isVisible: true
        },
        {
            route: managerStatus === "approved" ? '/organizador' : '/seja-organizador',
            name: managerStatus === "approved" || managerStatus === "pending" ? 'Organizador' : 'Seja um Organizador',
            icon: <img src={manager} alt="manager"/>, 
            activeIcon: <img src={manager_active} alt="manager"/>,
            isVisible: userInfo().isAdmin ? false : true
        },
        {
            route: '/regras',
            name: 'Regras',
            icon: <img src={manager} alt="Regras"/>, 
            activeIcon: <img src={manager_active} alt="Treinamento para Jogadores"/>,
            isVisible: true
        },
        // {
        //     name: 'FAQ',
        //     // route: managerStatus === "approved" ? '' : '',
        //     // name: managerStatus === "approved" || managerStatus === "pending" ? 'Boas Praticas Organizador' : 'Boas Praticas Jogador',
        //     icon: <img src={manager} alt="manager" />, 
        //     activeIcon: <img src={manager_active} alt="manager" />,
        //     // isVisible: userInfo().isAdmin ? false : true,
        //     isVisible: false,
        //     // onClick:handleDownload
        // },
        {
            route: '/tickets',
            name: 'Tickets',
            icon: <img src={ticket} alt="ticket"/>, 
            activeIcon: <img src={ticket_active} alt="ticket"/>,
            isVisible: true
        },
    ];

    const socials = [
        {
            label: 'Facebook',
            link: 'https://business.facebook.com/COPAG.Oficial/',
            icon: <FaFacebookF />
        },
        {
            label: 'Instagram',
            link: 'https://www.instagram.com/copagoficial/',
            icon: <FaInstagram />
        },
        {
            label: 'Twitter',
            link: 'https://twitter.com/copagoficial',
            icon: <FaTwitter />
        },
        {
            label: 'Linkedin',
            link: 'https://www.linkedin.com/showcase/promocionais-copag/',
            icon: <FaLinkedinIn />
        },
        {
            label: 'Youtube',
            link: 'https://www.youtube.com/user/CopagOficial',
            icon: <FaYoutube />
        },
    ]

    const renderMenuLnk = (menuLink: IMenuLink) => {
        return (
            <MenuLink
                key={menuLink.name}
                icon={menuLink.icon}
                activeIcon={menuLink.activeIcon}
                currentRoute={location.pathname === menuLink.route}
                name={menuLink.name}
                route={menuLink.route}
                onClick={menuLink.onClick}
            />
        )
    }

    return (
        <div id="menu-sidebar" className={customClass}>
            <ToastContainer />
            <Logo customClass="logo-sidebar" />
            <nav className="menu-nav">
                {menuLinks && menuLinks.map((item: IMenuLink) => {
                    if (item.isVisible) {
                        return renderMenuLnk(item)
                    }
                    return null;
                })}

                {userInfo().isAdmin &&
                    <>
                        <MenuLink
                            icon={<img src={manager} alt="manager"/>} 
                            activeIcon={<img src={manager_active} alt="manager"/>}
                            currentRoute={location.pathname === '/administrador'}
                            name="Administrador"
                            route="/administrador"
                        />
                        {/* <MenuLink
                            icon={<img src={quiz} alt="quiz" />} 
                            activeIcon={<img src={quiz_active} alt="quiz" />}
                            currentRoute={location.pathname === '/questionarios-admin'}
                            name="Questionários"
                            route="/questionarios-admin"
                        /> */}
                    </>
                }
                {/* {!userInfo().isAdmin &&
                    <MenuLink
                        icon={<LoyaltyPoints />}
                        activeIcon={<LoyaltyPoints/>}
                        currentRoute={location.pathname === '/questionarios'}
                        name="Questionários"
                        route="/questionarios"
                    />
                } */}
            </nav>

            <div className="contanier-socials">
                <SocialList socials={socials} />
            </div>
        </div>
    );
}

export default MenuSidebar;