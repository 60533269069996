import { useState, useContext, useEffect } from "react";
import BasePage from "../../components/BasePage";
import { ManagerStyle } from "./style";
import TabPane from "../../components/Tabs/TabPane";
import Tabs from "../../components/Tabs/Tabs";
import LeagueManager from "./LeagueManager";
import CreateOrEditTournament from "../../components/CreateOrEditTournament";
import SectionTitle from "../../components/SectionTitle";
import Timeline from "../../components/Timeline";
import Players from "./Players";
import WaitingForCopag from "./WaitingForCopag";
import Cards from "../../assets/svg/Cards";
import { AuthContext } from "../../contexts/UserContext";
import { useHistory, useLocation } from "react-router-dom";
import api from "../../services/api";
import { ITournament } from "../../interfaces/GeneralInterfaces";
import WaitingForCopagAnswer from "./WaitingForCopagAnswer";
import { ILeague } from "../../interfaces/LeagueInterfaces";
import { ToastContainer, toast } from "react-toastify";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Manager = () => {
  const [leagueInformation, setLeagueInformation] = useState<ILeague>(
    {} as ILeague
  );
  const { managerStatus, loading, handleManagerStatus, user } =
    useContext(AuthContext);
  const [manaterInterval, setManaterInterval] = useState<any>();
  const [shouldIntervalBeCancelled, setShouldIntervalBeCancelled] =
    useState(false);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [pendingTrucoCup, setPendingTrucoCup] = useState<ITournament>(
    {} as ITournament
  );
  const history = useHistory();
  const query = useQuery();
  const section = query.get("section");
  const [activeTab, setActiveTab] = useState("A LIGA");

  useEffect(() => {
    if (section === "4") {
      setActiveTab("AGENDAR COPA TRUCO");
    } else if (section === "2") {
      setActiveTab("JOGADORES");
    } else if (section === "3") {
      setActiveTab("AGENDAR TORNEIO");
    } else if (section === "5") {
      setActiveTab("BOAS PRATICAS PARA ORGANIZADORES");
    } else {
      setActiveTab("A LIGA");
    }
  }, []);

  const timelineSteps = [
    {
      name: "3 meses ou 8 torneios com no mínimo 4 duplas e no máximo 4 Copas Truco por mês",
      active: canCreate,
    },
    {
      name: "Todos torneios agendados devem ter realizado o report correto durante este período.",
      active: canCreate,
    },
    {
      name: "Envio de notificação para avaliação da solicitação de Copa Truco.",
      active: false,
    },
  ];

  const handleDownload = () => {
    api
      .get("/manuais/downloadPdf/")
      .then((response) => {
        if (response.data.filePath) {
          const filePath = response.data.filePath;
          const testePath = filePath.substring(filePath.lastIndexOf("/") + 1);
          const fileUrl = window.location.origin + `/media/doc/` + testePath;
          window.open(fileUrl, "_blank");
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        console.error("Erro ao baixar o arquivo:", error);
      });
  };

  const description = () => {
    return (
      <>
        <SectionTitle
          customClass="title-create-tournament"
          title="Agendar Torneio"
          icon={<Cards />}
        />
        {/* <br /> */}
        {/* <Text customClass="league-manager-text">
          Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit. Nullam hendrerit maximus fringilla.</strong> Etiam vitae egestas ante. Proin vestibulum nisl est, at feugiat odio porta vel. In sit amet nisi enim. Cras porta diam a nisi malesuada, vel volutpat mi feugiat. Nulla scelerisque pellentesque nibh, non lacinia tortor dignissim et.
          <br />
          Morbi imperdiet fermentum ex. Pellentesque id nibh mollis, accumsan velit ut, scelerisque quam. Praesent semper felis euismod ante efficitur, ullamcorper tempor ipsum fermentum. Nullam quis ex mi. Maecenas rutrum, lacus id volutpat rutrum, magna ex maximus tortor, quis scelerisque sem nisi eu lorem.
        </Text> */}
      </>
    );
  };

  const CupCopagTimeLine = () => {
    return (
      <>
        <br />
        <SectionTitle
          customClass="title-create-tournament"
          title="Condições necessárias para liberar os torneios"
        />
        <br />
        <Timeline steps={timelineSteps} />
      </>
    );
  };

  const getLeagueInfo = async () => {
    try {
      const response = await api.get("leagues/managed");
      setLeagueInformation(response.data);
      handleManagerStatus(response.data.league.approved);
      if (managerStatus !== "pending") setShouldIntervalBeCancelled(true);
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    const canCreateTournament = async () => {
      try {
        const response = await api.get(`tournaments/canCreate/${user?.id}`);
        setCanCreate(response.data);
      } catch (e: any) {
        console.log(e);
      }
    };

    if (!loading && user?.id) canCreateTournament();
  }, [loading]);

  useEffect(() => {
    const hasPendingTrucoCup = async () => {
      try {
        const response = await api.get(
          `tournaments/pending/${leagueInformation.league.id}`
        );
        setPendingTrucoCup(response.data);
      } catch (e: any) {
        console.log(e);
      }
    };

    if (leagueInformation && leagueInformation?.league?.id)
      hasPendingTrucoCup();
  }, [leagueInformation]);

  useEffect(() => {
    if (managerStatus === "recused") history.push("seja-organizador");
  }, [managerStatus]);

  useEffect(() => {
    let myIntervalID: any;
    if (!loading) {
      getLeagueInfo();
      myIntervalID = setInterval(getLeagueInfo, 5000 * 60);
      setManaterInterval(myIntervalID);
    }

    return () => clearInterval(myIntervalID);
  }, [loading]);

  useEffect(() => {
    if (shouldIntervalBeCancelled) clearInterval(manaterInterval);
  }, [shouldIntervalBeCancelled]);

  const RenderContent = () => {
    if (managerStatus === "pending") {
      return <WaitingForCopag />;
    } else {
      return (
        <ManagerStyle>
          <ToastContainer />
          <Tabs tabActive={activeTab}>

          <TabPane label="AGENDAR TORNEIO">
              <CreateOrEditTournament
                category="Tournament"
                Description={description}
                edit={false}
                displayForm={true}
              />
            </TabPane>

            <TabPane label="A LIGA (Aqui terá Torneio Rápido)">
              {leagueInformation && leagueInformation.league && (
                <LeagueManager league={leagueInformation} />
              )}
            </TabPane>

            {/* <TabPane label="JOGADORES">
              <Players leagueId={leagueInformation.league?.id} />
            </TabPane> */}

            {/* <TabPane label="AGENDAR COPA TRUCO">
              {pendingTrucoCup ? (
                <WaitingForCopagAnswer tournament={pendingTrucoCup} />
              ) : (
                <CreateOrEditTournament
                  category="TrucoCup"
                  Description={description}
                  Timeline={CupCopagTimeLine}
                  edit={false}
                  displayForm={canCreate}
                />
              )}
            </TabPane> */}

            {/* <TabPane
              label="BOAS PRATICAS PARA ORGANIZADORES"
              action={handleDownload}
            >
              {leagueInformation && leagueInformation.league && (
                <LeagueManager league={leagueInformation} />
              )}
            </TabPane> */}
          </Tabs>
        </ManagerStyle>
      );
    }
  };

  return (
    <BasePage title="Organizador">
      <RenderContent />
    </BasePage>
  );
};

export default Manager;
