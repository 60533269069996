import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import Button from "../../../components/Button";
import ErrorFocus from "../../../components/ErrorFocus";
import InputField from "../../../components/InputField";
import SelectField from "../../../components/SelectField";
import { Container } from "./styles";

const tournamentTypeOptions = [
  { value: "Swiss", label: "Chaveamento suiço" },
  { value: "Knockout", label: "Mata-mata" },
];

const teamCountOptions = [
  { value: 2, label: "Duplas" },
  { value: 3, label: "Trios" },
];

const CreateForm = () => {
  const [initialValues, setInitialValues] = useState({});

  return (
    <Container>
      <Formik
        initialValues={{}}
        // validationSchema={CreateTournamentSchema}
        // validateOnChange={false}
        // validateOnBlur={false}
        // enableReinitialize
        onSubmit={(
          values: any,
          { setSubmitting }: FormikHelpers<any>,
        ) => {
          setSubmitting(false);
        }}
      >
        {(formik) => {
          return (
            <Form className="common-form">
              <ErrorFocus />

              <InputField name="name" label="Nome" type="text" />

              <SelectField
                id="type"
                label="Tipo do torneio"
                name="type"
                options={tournamentTypeOptions}
                placeholder="Selecione o tipo do torneio"
              />

              <SelectField
                label="Formato de time"
                name="teamCount"
                options={teamCountOptions}
                placeholder="Selecione o formato de Time"
              />

              {/* {isModalOpen && (  */}
              {/*   <Modal  */}
              {/*     isOpen={isModalOpen}  */}
              {/*     onCloseModal={handleClose}  */}
              {/*     classStatus={returnStatus ? "success" : "error"}  */}
              {/*     title={returnStatus ? "Sucesso!" : "Opss!"}  */}
              {/*     text={returnMessage}  */}
              {/*   />  */}
              {/* )}  */}

              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Button
                  classButton="submit-button"
                  hasLoader={true}
                  showLoading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  label="CRIAR"
                  type={"submit"}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default CreateForm;
