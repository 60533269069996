import React, { useState, useContext, useEffect, ChangeEvent } from 'react';
import { AuthContext } from './../../../contexts/UserContext';
import { DownloadStyle } from './style';
import * as Yup from "yup";
import api from '../../../services/api'
import { Formik, Form, ErrorMessage, FormikValues } from 'formik';
import ErrorFocus from "../../../components/ErrorFocus"; 
import Button from '../../../components/Button';
import Modal from "../../../components/Modal";

const ManuaisSchema = Yup.object().shape({
    file: Yup.string().required("Você deve fazer o upload de um arquivo."),
});

const ManuaisDownload = () => {
    const { loading, userInfo } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [returnMessage, setReturnMessage] = useState("");
    const [returnStatus, setReturnStatus] = useState(false);
    const [file, setFile] = useState<File | null>(null); 
    const [selectedFileName, setSelectedFileName] = useState('');
    const [fileUrl, setFileUrl] = useState(''); 

    const fetchLatestManual = async () => {
        try {
            const response = await api.get('manuais/downloadPdf');
            if (response.data.filePath) {
                setFileUrl(response.data.filePath);
                setSelectedFileName(response.data.filePath.substring(response.data.filePath.lastIndexOf('/') + 1));
            }
        } catch (error) {
            console.error("Erro ao buscar o arquivo:", error);
        }
    };

    useEffect(() => {
        fetchLatestManual();
    }, []);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any) => void) => {
        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
            const selectedFile = event.currentTarget.files[0];
            setFile(selectedFile); 
            setFieldValue('file', selectedFile);
            setSelectedFileName(selectedFile.name); 
        }
    };

    const handleSubmit = async (values: FormikValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        if (file) { 
            const formData = new FormData();
            formData.append('file_name', file.name);
            formData.append('file_path', file);

            try {
                const response = await api.post(`manuais/uploadPdf`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                setReturnStatus(true);
                setReturnMessage("Arquivo carregado com sucesso!");
                setIsModalOpen(true);
  
                const { fileUrl } = response.data;
                setFileUrl(fileUrl);

            } catch (error) {
                setReturnMessage("Erro: " + error);
                setIsModalOpen(true);
            }
            setSubmitting(false);
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <DownloadStyle>
            {loading && <div className="">Carregando...</div>}
            <div>
                {fileUrl && (
                    <div>
                        <h3>Faça o download das regras:</h3>
                        {/* <a className="rule-file" href={fileUrl} target="_blank" download rel="noreferrer">Normas e Regras da Plataforma</a> */}
                        <a className="rule-file" href={fileUrl} target="_blank" download rel="noreferrer">{selectedFileName}</a>
                    </div>
                )}
                {userInfo().isAdmin && (
                <Formik
                    initialValues={{ file: null }}
                    validationSchema={ManuaisSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                            <ErrorFocus />
                            <br />
                            <label className='custom-title-campos'> Upload de regra: </label>
                            <label className="custom-file-upload">
                                <input type="file" onChange={(event) => handleFileChange(event, setFieldValue)}  />
                                <span className="file-btn"></span>
                                <span className="file-selected">{selectedFileName || 'Nenhum arquivo escolhido'}</span>
                            </label>
                            <ErrorMessage name="file" component="div" />
                            {isModalOpen && (
                                <Modal
                                  isOpen={isModalOpen}
                                  onCloseModal={handleClose}
                                  classStatus={returnStatus ? "success" : "error"}
                                  title={returnStatus ? "Sucesso!" : "Opss!"}
                                  text={returnMessage}
                                />
                              )}
                            <br />
                            <br />
                            <Button type="submit" label="Enviar" disabled={isSubmitting} />
                        </Form>
                    )}
                </Formik>
                )}
            </div>
        </DownloadStyle>
    );
};

export default ManuaisDownload;
