import styled from 'styled-components'

const SelectFieldStyle = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    position: relative;
    label {
        display: inline-block;
        color: var(--primary);
        font-size: 1.5rem;
        font-family: var(--font-primary-bold);
        transition: color .3s;
        margin-bottom: .9rem;
    }

    .error-message{
        margin: .5rem 0;
        color: var(--danger);
        font-size: 1.2rem;
        transition: height .3s, opacity .3s;
    }
    &.input-error{

        div:first-of-type{
            border-color: var(--danger);
        }
        
        .error-message{
            height: 14px;
            opacity: 1;
        }
    }
    
`

export default SelectFieldStyle
