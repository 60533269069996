import { useState, useContext, useEffect } from "react";
import { ITournament } from "../../../interfaces/GeneralInterfaces";
import Text from "../../../components/Text";
import Title from "../../../components/Title";
import SectionTitle from "../../../components/SectionTitle";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import { ToastContainer, toast } from "react-toastify";
import { TournamentInformationsStyle } from "./style";
import ModalInvitePlayers from "./ModalInvitePlayers";
import { AuthContext } from "../../../contexts/UserContext/UserContext";
import api from "../../../services/api";
import QRCode from "qrcode.react";
import EmailIcon from "../../../assets/svg/EmailIcon";
import PrintIcon from "../../../assets/svg/PrintIcon";
import { TournamentStatus } from "../../../enums/GeneralEnums";
import { useHistory } from "react-router";
import Tooltip from "../../../components/Tooltip";
import { TournamentContext } from "../Context/TournamentContext";
import {
  renderDateFormated,
  renderFormatedHour,
} from "../../../utils/formatData";
import pdfContainer from "../../../utils/pdfContainer";
import { IPlayer } from "../../../interfaces/GeneralInterfaces";
import CsvIcon from "../../../assets/svg/CsvIcon";
import { CSVLink } from "react-csv";
// import { go } from "react-icons/go";
import { IoCopyOutline, IoCopySharp } from "react-icons/io5";

interface ITournamentInformationsProps {
  tournament: ITournament;
  players: IPlayer[];
  tournamentStatus: TournamentStatus;
  subscription: boolean;
  approvedAndSubscribed: boolean;
}

const renderCategory = (values: any) => {
  if (values === "Tournament") {
    return "Torneio";
  } else if (values === "TrucoCup") {
    return "Copa Truco";
  } else {
    return "Estadual Copag";
  }
};

const TournamentInformations = ({
  tournament,
  players,
  tournamentStatus,
  subscription,
  approvedAndSubscribed,
}: ITournamentInformationsProps) => {
  const {
    handleLoading,
    loading,
    handleIsLeagueManagerOfThisTournament,
    user,
  } = useContext(AuthContext);
  const { ranking, handleRanking } = useContext(TournamentContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubscribedModalOpen, setIsSubscribedModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSetIsCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] =
    useState<boolean>(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [copied, setCopied] = useState(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const history = useHistory();
  const userTournament = players.map((data: IPlayer) => data.user);

  const handleModalSubscribe = async () => setIsSubscribedModalOpen(true);
  const handleModalCancelSubscription = async () =>
    setIsCancelSubscriptionModalOpen(true);

  const copyToClipboard = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl);
    setCopied(true);

    setIsModalOpen(false);
    toast.success(`Link Copiado`, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    setCopied(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    toast.success(`Convite enviado com sucesso`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleSubscribe = async () => {
    handleLoading(true);
    try {
      const body = { tournamentId: tournament.id };
      const tournamentSubscribed = await api.post(
        "/tournaments/subscribe/",
        body
      );
      console.log("tournamentSubscribed", tournamentSubscribed);
      if (tournamentSubscribed.data) {
        throw new Error(tournamentSubscribed.data.message);
      }
      setSuccess("Inscrição realizada com sucesso!");
      setIsSubscribedModalOpen(false);
      setIsModalSuccessOpen(true);
    } catch (e: any) {
      console.log("error", e);
      setError(e.message);
      setIsModalOpen(false);
      setIsModalErrorOpen(true);
    } finally {
      handleLoading(false);
    }
  };

  const handleDelete = async () => {
    handleLoading(true);
    try {
      await api.delete(`/tournaments/${tournament.id}`);
      setIsDeleteModalOpen(false);

      history.push(`/organizador?section=1`);

      toast.success(`Exclusão realizada com sucesso`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (e: any) {
      setError(e.message);
      setIsModalOpen(false);
      setIsModalErrorOpen(true);
    } finally {
      handleLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    handleLoading(true);
    try {
      const body = { userId: user.id };
      await api.post(`/tournaments/${tournament.id}/cancelSubscription`, body);
      setSuccess("Inscrição cancelada com sucesso!");
      setIsCancelSubscriptionModalOpen(false);
      setIsModalSuccessOpen(true);
    } catch (e: any) {
      setError(e.message);
      setIsModalOpen(false);
      setIsModalErrorOpen(true);
    } finally {
      handleLoading(false);
    }
  };

  const handleShareQRCode = () => {
    setIsModalOpen(true);
  };

  const handleEditTournament = () =>
    history.push(`/editar-torneio/${tournament.id}`);

  useEffect(() => {
    if (tournamentStatus === TournamentStatus.notStarted) {
      setStatus("Torneio ainda não iniciado pelo organizador.");
    } else if (tournamentStatus === TournamentStatus.inProgress) {
      setStatus("Torneio em andamento!");
    } else {
      setStatus("Torneio finalizado!");
    }
  }, [tournamentStatus]);

  useEffect(() => {
    const getPendingAndApprovedPlayers = async () => {
      try {
        const responsePending = await api.get(
          `tournaments/${tournament.id}/users?status=pending`
        );
        const responseApproved = await api.get(
          `tournaments/${tournament.id}/users?status=approved`
        );
        responseApproved.data.length > 0 || responsePending.data.length > 0
          ? setCanEdit(false)
          : setCanEdit(true);
      } catch (e: any) {
        console.log(e);
      }
    };

    if (tournament.id) {
      getPendingAndApprovedPlayers();
      handleRanking();
    }
  }, [tournament]);

  return (
    <TournamentInformationsStyle>
      <ToastContainer />
      <Title customClass={"tournament-title"}>
        {tournament?.name ?? "Copa Truco"}
      </Title>
      <Text>{tournament?.leagueName ?? ""}</Text>
      <br />
      <Text>{tournament?.description ?? ""}</Text>
      {subscription && approvedAndSubscribed && (
        <Text>
          <br />
          {tournamentStatus === TournamentStatus.notStarted &&
            "Sua inscrição já foi realizada e aprovada pelo organizador. Aguarde o organizador do evento aprová-lo e iniciar o torneio."}
        </Text>
      )}

      {subscription && !approvedAndSubscribed && (
        <Text>
          <br />
          {tournamentStatus === TournamentStatus.notStarted &&
            "Sua inscrição já foi realizada e aguarda a aprovação do organizador."}
        </Text>
      )}

      <div className="tournaments-informations">
        <ul className="list-infos">
          <li>
            <strong>DATA:</strong> {renderDateFormated(tournament.date)}{" "}
          </li>
          <li>
            <strong>HORÁRIO:</strong> {renderFormatedHour(tournament.date)}{" "}
          </li>
          <li>
            <strong>TIPO DE PARTIDA:</strong> {tournament.modality}{" "}
          </li>
          <li>
            <strong>ESTADO:</strong>{" "}
            {tournament?.state?.name ?? "Torneio Online"}{" "}
          </li>
          <li>
            <strong>CIDADE:</strong>{" "}
            {tournament?.city?.name ?? "Torneio Online"}{" "}
          </li>
          <li>
            <strong>STATUS:</strong> {status}{" "}
          </li>
          <li>
            <strong>CATEGORIA:</strong> {renderCategory(tournament.category)}{" "}
          </li>
          <li>
            <strong>DATA CHECKIN:</strong>{" "}
            {renderDateFormated(tournament.checkin)}{" "}
          </li>
          <li>
            <strong>HORÁRIO CHECKIN:</strong>{" "}
            {renderFormatedHour(tournament.checkin)}{" "}
          </li>
          <li>
            <strong>FORMATO:</strong>{" "}
            {tournament.format === "Presential" ? "Presencial" : "Online"}{" "}
          </li>
          <li>
            <strong>MODALIDADE:</strong>{" "}
            {tournament.modality === "TorneioMineiro"
              ? "Truco Mineiro"
              : "Truco Paulista"}{" "}
          </li>
          <li>
            <strong>PREÇO:</strong> R$ {tournament.price}{" "}
          </li>
          <li>
            <strong>DUPLA OU TRIO:</strong>{" "}
            {tournament.teamCount === 2 ? "Dupla" : "Trio"}{" "}
          </li>
          {tournament.maxParticipants && (
            <li>
              <strong>LIMITE DE JOGADORES:</strong> {tournament.maxParticipants}
            </li>
          )}
          <li>
            <strong>VISIBILIDADE:</strong>{" "}
            {tournament.visibility === "Public" ? "Público" : "Privado"}{" "}
          </li>
          <li>
            <strong>CONTATO ORGANIZADOR:</strong> {tournament.managerEmail}{" "}
          </li>
          <li>
            <strong>NOME ORGANIZADOR:</strong> {tournament.managerName}{" "}
          </li>
          <li>
            <strong>PRÊMIO:</strong>{" "}
            {tournament?.prize ?? "Nenhum prêmio informado."}{" "}
          </li>
          <li>
            <strong>TIPO DO TORNEIO:</strong>{" "}
            {tournament?.type === "Knockout"
              ? "Mata Mata"
              : "Chaveamento Suiço"}
          </li>
          {tournamentStatus === TournamentStatus.finished && (
            <li>
              <strong>TIME VENCEDOR:</strong>{" "}
              {ranking[0]?.team.name +
                "  (" +
                ranking[0]?.team.users[0]?.username +
                " e " +
                ranking[0]?.team.users[1]?.username +
                ")"}
            </li>
          )}
          {tournamentStatus === TournamentStatus.finished && (
            <li>
              <strong>PONTUAÇÃO TIME VENCEDOR:</strong> {ranking[0]?.team.score}
            </li>
          )}
        </ul>

        <div className="qr-code">
          <div className="actions">
            {tournamentStatus === TournamentStatus.notStarted && (
              <button onClick={() => handleShareQRCode()}>
                <Tooltip label="Convide por E-mail" themeColor="primary">
                  <EmailIcon />
                </Tooltip>
              </button>
            )}

            {tournamentStatus === TournamentStatus.notStarted && (
              <button onClick={copyToClipboard}>
                <Tooltip
                  label="Link para inscrição do torneio"
                  themeColor="primary"
                >
                  <IoCopySharp />
                </Tooltip>
              </button>
            )}

            {/* <button>
              <CSVLink
                data={userTournament}
                onClick={() => {
                  console.log("You click the link");
                  //   return false; // 👍🏻 You are stopping the handling of component
                }}
              >
                <Tooltip label="Baixar CSV" themeColor="primary">
                  <CsvIcon />
                </Tooltip>
              </CSVLink>
            </button>

            <button onClick={() => pdfContainer(players, tournament)}>
              <Tooltip label="Imprimir" themeColor="primary">
                <PrintIcon />
              </Tooltip>
            </button> */}
          </div>

          <QRCode
            value={window.location.href}
            size={160}
            bgColor={"#EEE9F0"}
            fgColor={"#2C1266"}
            level={"L"}
            includeMargin={false}
            renderAs={"svg"}
          />
          <br />
          {handleIsLeagueManagerOfThisTournament(tournament.managerEmail) &&
            tournamentStatus === TournamentStatus.notStarted && (
              <Button
                label="CONVIDAR"
                eventCallback={() => setIsModalOpen(true)}
              />
            )}
          {!tournament.subscribed &&
            tournamentStatus === TournamentStatus.notStarted && (
              <Button
                label={
                  tournament.subscribedUsersCount !== undefined &&
                  tournament.maxParticipants !== undefined &&
                  tournament.subscribedUsersCount < tournament.maxParticipants
                    ? "INSCRIÇÃO"
                    : "VAGAS ESGOTADAS"
                }
                eventCallback={
                  tournament.subscribedUsersCount !== undefined &&
                  tournament.maxParticipants !== undefined &&
                  tournament.subscribedUsersCount < tournament.maxParticipants
                    ? handleModalSubscribe
                    : undefined
                }
                disabled={
                  tournament.subscribedUsersCount === undefined ||
                  tournament.maxParticipants === undefined ||
                  tournament.subscribedUsersCount >= tournament.maxParticipants
                }
              />
            )}
          {tournament.subscribed &&
            tournamentStatus === TournamentStatus.notStarted &&
            !approvedAndSubscribed && (
              <Button
                label={"CANCELAR INSCRIÇÃO"}
                eventCallback={handleModalCancelSubscription}
              />
            )}
            {canEdit &&
              handleIsLeagueManagerOfThisTournament(tournament.managerEmail) &&
               (tournamentStatus === TournamentStatus.notStarted || tournamentStatus === TournamentStatus.inProgress) && (
                  <>
                    <Button label={"EDITAR"} eventCallback={handleEditTournament} />
                    <Button label="EXCLUIR" eventCallback={() => setIsDeleteModalOpen(true)} />
                      </>
            )}  
             
            {/* {canEdit &&
            handleIsLeagueManagerOfThisTournament(tournament.managerEmail) && (
              <>
                <Button label={"EDITAR"} eventCallback={handleEditTournament} />
                <Button label="EXCLUIR" eventCallback={() => setIsDeleteModalOpen(true)}
                />
              </>
            )} */}

        </div>
      </div>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onCloseModal={() => setIsModalOpen(false)}
          classStatus="success"
          title="Convidar Jogadores"
        >
          <ModalInvitePlayers
            tournamentId={tournament?.id}
            closeModal={handleCloseModal}
            shareQRCode={false}
          />
        </Modal>
      )}

      {isSubscribedModalOpen && (
        <Modal
          isOpen={isSubscribedModalOpen}
          onCloseModal={() => setIsSubscribedModalOpen(false)}
          classStatus="warning"
        >
          <SectionTitle title={"INSCRIÇÃO"} />
          <br />
          <span>
            Deseja realizar a inscrição no torneio {tournament?.name ?? ""}?
          </span>
          <br />
          <div className="buttons-style">
            {!loading && (
              <Button
                classButton={"small"}
                eventCallback={handleSubscribe}
                label={"SIM"}
              />
            )}
            {!loading && (
              <Button
                classButton={"small"}
                eventCallback={(e: any) => setIsSubscribedModalOpen(false)}
                label={"CANCELAR"}
              />
            )}
          </div>
        </Modal>
      )}

      {isSetIsCancelSubscriptionModalOpen && (
        <Modal
          isOpen={isSetIsCancelSubscriptionModalOpen}
          onCloseModal={() => setIsCancelSubscriptionModalOpen(false)}
          classStatus="warning"
        >
          <SectionTitle title={"CANCELAR INSCRIÇÃO"} />
          <br />
          <span>
            Deseja cancelar a sua inscrição no torneio {tournament?.name ?? ""}?
          </span>
          <br />
          <div className="buttons-style">
            {!loading && (
              <Button
                classButton={"small"}
                eventCallback={handleCancelSubscription}
                label={"SIM"}
              />
            )}
            {!loading && (
              <Button
                classButton={"small"}
                eventCallback={(e: any) =>
                  setIsCancelSubscriptionModalOpen(false)
                }
                label={"CANCELAR"}
              />
            )}
          </div>
        </Modal>
      )}

      {isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          onCloseModal={() => setIsDeleteModalOpen(false)}
          classStatus="error"
        >
          <SectionTitle title={"EXCLUIR"} />
          <br />
          <span>
            Deseja realmente excluir o torneio {tournament?.name ?? ""}?
          </span>
          <br />
          <div className="buttons-style">
            {!loading && (
              <Button
                classButton={"small"}
                eventCallback={handleDelete}
                label={"EXCLUIR"}
              />
            )}
            {!loading && (
              <Button
                classButton={"small"}
                eventCallback={(e: any) => setIsDeleteModalOpen(false)}
                label={"CANCELAR"}
              />
            )}
          </div>
        </Modal>
      )}

      {isModalErrorOpen && (
        <Modal
          isOpen={isModalErrorOpen}
          onCloseModal={() => setIsModalErrorOpen(false)}
          classStatus="error"
          title="Erro"
          text={error}
        />
      )}

      {isModalSuccessOpen && (
        <Modal
          isOpen={isModalSuccessOpen}
          onCloseModal={() => setIsModalSuccessOpen(false)}
          classStatus="success"
          title="Sucesso"
          text={success}
        />
      )}
    </TournamentInformationsStyle>
  );
};

export default TournamentInformations;
