import styled from 'styled-components'

export const DownloadStyle = styled.section`
    background:#FFF;
    min-height:500px;

    .container-accordion{
      margin-bottom:30px;
    } 
    
    .custom-title-campos {
      // display: inline-block;
      // padding: 6px 12px;
      // cursor: pointer;
      // border: 1px solid #ccc;
      // border-radius: 4px;
      width: 100%;
      display: inline-block;
      color: var(--primary);
      font-size: 1.5rem;
      font-family: var(--font-primary-bold);
      -webkit-transition: color .3s;
      transition: color .3s;
      margin-bottom: .9rem;
    }

    .custom-file-upload {
      position: relative;
      display: inline-block;
      border: 1px solid #ccc;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    
    .custom-file-upload input[type="file"] {
      // width: 100%;
      // height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
    
    .custom-file-upload .file-btn {
      display: inline-block;
      width: 100%;
      color: #fff;
      cursor: pointer;
      border-radius: 4px;
      min-height: 28px;
      flex: 1;
    }
    
    .custom-file-upload .file-selected {
      display: block;
      margin-top: 3px;
      font-size: 14px;
      color: black;
      flex: 5;
    }
    
    .custom-file-upload .file-btn::before {
      content: 'Escolher arquivo';
      display: inline;
      padding: 2px;
      border-radius: 3px;
      position: relative;
      top: 0px;
      right: 0px;
      bottom: -1px;
      // z-index: 6;
      height: 1.5rem;
      padding: .5rem 1rem;
      line-height: 1.9;
      color: #f1f1f1;
      background-color: #333;
      border: 1px solid #333;
      border-radius: 3px 0px 0px 3px;
      background-color: var(--primary);
    }
    .download-button {
      background-color: #4CAF50; /* Cor de fundo */
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 8px; /* Borda arredondada */
    }
    
    .download-button:hover {
      background-color: #45a049; /* Mudar a cor de fundo ao passar o mouse */
    }
    
    .file-name {
      margin-left: 10px;
    }

    .faq-text {
      font-size: 3rem;
      }
    
      .rule-file {
        font-size: 1.5rem;
        font-weight: bold;
        color: #8D109A;
        text-decoration: none;
        }
     
`