import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import api from '../../services/api'
import * as Yup from 'yup';

//context
import { AuthContext } from './../../contexts/UserContext';
import { ILoginFormValues, IRecoverFormValues } from '../../interfaces/GeneralInterfaces';

//Assets
import LoginStyle from './style';
import logo from './../../assets/images/copag.png';
import logoTruco from './../../assets/images/plataforma_truco.png';

//Components
import Modal from '../../components/Modal';
import InputField from '../../components/InputField';
import Button from '../../components/Button';

const SignupSchema = Yup.object().shape({
  login: Yup.string().required('Informe seu email ou nome do usuário'),
  password: Yup.string()
    .required('Informe sua senha')
    .min(3, 'Senha muito curta. Informe uma senha com mais de 8 caracteres\n')
    .matches(/^.{3,}$/, 'A senha informada deve conter entre 8 e 20 caracteres\n')
  // .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, 'Password can only contain Latin letters.')
});

const RecoverPassSchema = Yup.object().shape({
  email: Yup.string().email('Informe um email válido').required('Informe seu email'),
});

const Login = () => {
  const { handleLogin } = useContext(AuthContext);
  const history = useHistory();

  const [showForgotPass, setShowForgotPass] = useState(false);
  const [showLoginResponseModal, setShowLoginResponseModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showFailRecoverModal, setShowFailRecoverModal] = useState(false);
  const [loginError, setLoginError] = useState<boolean>(true);

  const loginInitialValues: ILoginFormValues = {
    login: '',
    password: ''
  };

  const recoverInitialValues: IRecoverFormValues = {
    email: '',
  };

  const submitLogin = async (values: ILoginFormValues) => {
    setLoginError(true);
    const login = await handleLogin({
      login: values.login,
      password: values.password
    });

    setLoginError(login);

    if (login) {
      history.push('home');
    }

  }

  const submitRecover = async (values: IRecoverFormValues) => {

    try {
      const email = values.email;

      const response = await api.post("users/forgot_password", { email: email })

      if (response.status === 200) {
        setShowRecoverModal(true);
        setTimeout(() => {
          history.push('/login');
        }, 1000);

      } else {
        setShowFailRecoverModal(true);
      }

    } catch (err: any) {
      setShowFailRecoverModal(true);
    }

  }

  return (
    <LoginStyle>
      <main className="wrapper-limiter">
        <div className="description">
          <div className="logos">
            <img src={logoTruco} width="150" height="75" alt="Plataforma de Truco" />
            <img src={logo} width="72" height="46" alt="Copag" />
          </div>

          <div className="container-text">
            <h2 className="description-title">Bem-vindo à Truco Oficial!</h2>

            <div className="common-text description-text">
              <p>A Plataforma Truco Oficial é uma solução desenvolvida pensando nos Organizadores e Jogadores de todo país.
Para os Organizadores, a Truco Oficial possibilita a divulgação gratuita de Ligas e Torneios. Assim, é possível ter visibilidade nacional de eventos divulgados na plataforma, e ainda é possível contar com a solução para a gestão de evento físicos.</p>
              <br />
              <p>Além disso, os usuários da Truco Oficial contam com o benefício exclusivo do Programa de Recompensa: você participa de torneios gerados na plataforma e, de acordo com sua posição de ranking, recebe pontuação que será acumulada para a troca por produtos.</p>
              <br />
              <br />
              <strong>Então se prepare pra gritar Truco e ganhar muitoooooo!</strong>
            </div>
          </div>
        </div>

        <div className="login-column">
          <div className="fast-tournaments-section">
            <span className="fast-tournament-call-title">
              Quer organizar um torneio?
            </span>
            <span className="fast-tournament-call-description">
              O Truco Oficial permite que você crie, organize e agende seu
              torneio gratuitamente pela plataforma.
              <strong> Bora jogar?</strong>
            </span>
            <div className="button-wrapper">
              <Button 
                link="/torneios-rapidos"
                themeColor="green"
                hasLoader={true}
                label="ORGANIZAR TORNEIO"
              />
            </div>
          </div>

          <div className="login-section-divider">
            <hr />
            <p>Ou</p>
            <hr />
          </div>

          <div className={'container-forms' + (showForgotPass ? ' open-recover' : '')}>

            <div id="container-form-login" className="form-content">
              <div className="social-login">
                {/* <Button label="ENTRAR PELO FACEBOOK" classButton="social-facebook" leftIcon={<FaFacebookF />} eventCallback={() => socialLogin('facebook')} />
                              <Button label="ENTRAR PELO GOOGLE" classButton="social-google" leftIcon={<FaGoogle />} eventCallback={() => socialLogin('facebook')} /> */}
              </div>

              <Formik
                initialValues={loginInitialValues}
                validationSchema={SignupSchema}
                onSubmit={(values: ILoginFormValues) => {
                  submitLogin(values)
                }}
              >
                {formik => {
                  return (
                    <Form className="common-form">
                      <InputField
                        name="login"
                        label="Digite seu nome de usuário ou número do jogador"
                        type="text"
                      />
                      <InputField
                        name="password"
                        label="Digite sua senha"
                        type="password"
                      />
                      {!loginError &&
                        <div className="error-login">Usuário ou senha estão incorretos</div>
                      }

                      <button type="button" className="forgot-pass-link" onClick={() => setShowForgotPass(!showForgotPass)} title="Esqueci minha senha">Esqueci minha senha</button>
                      <Button classButton={!loginError ? 'submit-button error-login-btn' : 'submit-button'} hasLoader={true} label="ENTRAR" type={'submit'} />

                      <div className="register-link">
                        <span>Você não tem uma conta? </span>
                        <Link to={'./register'} className="register-button" title="Faça o cadastro!">Faça o cadastro!</Link>
                      </div>
                    </Form>
                  )
                }}
              </Formik>

            </div>

            <div className="form-content" id="container-form-recover">
              <h2 className="title-form">Informe seu email e siga as intruções para redefinir sua senha</h2>

              <Formik
                initialValues={recoverInitialValues}
                validationSchema={RecoverPassSchema}
                onSubmit={(
                  values: IRecoverFormValues,
                  { setSubmitting }: FormikHelpers<IRecoverFormValues>,
                  // { isSubmitting }: FormikState<IRecoverFormValues>,
                ) => {
                  submitRecover(values);
                  setSubmitting(false);
                  // setTimeout(() => {

                  //    setSubmitting(false);
                  // }, 5000);
                }}
              >
                {(props: FormikProps<IRecoverFormValues>) => {
                  const {
                    touched,
                    errors,
                    isSubmitting,
                    setFieldValue,
                  } = props
                  return (
                    <Form className="common-form">
                      <InputField
                        name="email"
                        label="Digite seu e-mail"
                        type="email"
                        onChangeCallback={(value: string) => setFieldValue('email', value)}
                        errorMessage={errors.email && touched.email ? errors.email : ''}
                      />

                      <button type="button" className="forgot-pass-link" onClick={() => setShowForgotPass(!showForgotPass)} title="Voltar para login">Voltar para login</button>
                      <Button classButton="submit-button" hasLoader={true} showLoading={isSubmitting} disabled={isSubmitting} label="Recuperar senha" type={'submit'} />
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </main>

      {showLoginResponseModal && (
        <Modal
          isOpen={showLoginResponseModal}
          onCloseModal={() => setShowLoginResponseModal(false)}
          classStatus="warning"
          title="Opss!"
          text="Ocorreu um erro ao fazer login. Por favor, tente novamente mais tade."
        />

      )}

      {showRecoverModal && (
        <Modal
          isOpen={showRecoverModal}
          onCloseModal={() => setShowRecoverModal(false)}
          classStatus="success"
          title="Feito!"
          text="Em breve você vai receber um e-mail para informar sua nova senha."
        />
      )}

      {showFailRecoverModal && (
        <Modal
          isOpen={showFailRecoverModal}
          onCloseModal={() => setShowFailRecoverModal(false)}
          classStatus="warning"
          title="Opss!"
          text="E-mail não encontrado, tente novamente."
        />

      )}


    </LoginStyle>
  );
}

export default Login;
