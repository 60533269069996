import { useState, useContext, useEffect } from "react";
import ReactLoading from "react-loading";
import BasePage from "../../components/BasePage";
import api from "../../services/api";
import Modal from "../../components/Modal";
import { HomeStyle } from "./style";
import { AuthContext } from "./../../contexts/UserContext";
import PointsWidget from "../../components/PointsWidget";
import LoyaltyPoints from "../../assets/svg/LoyaltyPoints";
import RedemPoints from "../../assets/svg/RedemPoints";
import SectionTitle from "../../components/SectionTitle";
import { BlogList } from "../../components/Blog";
import UserInformation from "../../components/UserInformation";
import TableSubscribedTournaments from "../../components/TableSubscribedTournaments";
import TableNextsTournaments from "../../components/TableNextsTournaments";
import axios from "axios";
import ProfilePicture from "../../assets/images/profile.jpg";
import ranking from "../../assets/images/ranking.png";
import { useHistory } from 'react-router-dom';

const columnsInfoUser = [
  {
    Header: "TORNEIO",
    accessor: "tournamentName",
  },
  {
    Header: "COLOCAÇÃO",
    accessor: "placement",
  },
];

interface IMyRankings {
  tournamentName: string;
  placement: string;
}

interface IBlog {
  slug: string;
  image: string;
  title: string;
  text: string;
}

const Home = () => {
  const { userInfo, loading, user } = useContext(AuthContext);
  const [myrankings, setMyRankings] = useState<IMyRankings[]>(
    {} as IMyRankings[]
  );
  const [userPoints, setUserPoints] = useState<number>();
  const [orders, setOrders] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const history = useHistory();

  const rankingLimiter = myrankings.length > 0 ? myrankings.slice(-3) : [];

  const getRankings = async () => {
    try {
      const response = await api.get("/tournamentRanking/user");
      const parseData = response?.data?.map((ranking: IMyRankings) => {
        return {
          tournamentName: ranking.tournamentName,
          placement: `${ranking.placement} ° lugar`,
        };
      });
      setMyRankings(parseData);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!loading) getRankings();
  }, [loading]);

  useEffect(() => {
    (async () => {
      if (!loading) {
        try {
          const responseUserPoints = await api.get("users/trocapontos");
          const responseOrders = await api.get("users/trocapontos/orders");

          let count = 0;

          if (responseOrders.data) {
            count = responseOrders.data.data
              .map((items: any) => items.order_total)
              .reduce((acc: number, value: number) => {
                return acc + value;
              });
          }

          setOrders(count);
          setUserPoints(
            responseUserPoints?.data?.balance
              ? responseUserPoints.data.balance
              : 0
          );
        } catch (e: any) {
          setOrders(0);
          setUserPoints(0);
          console.log(e);
        }
      }
    })();
  }, [loading]);

  return (
    <BasePage
      title={`Olá, ${userInfo().name}`}
    >
      <HomeStyle>
        <div className="information-top">
          <div className="left-information">
            {/* <div className="points-widgets">
              <PointsWidget
                icon={<LoyaltyPoints />}
                points={
                  userPoints ?? (
                    <ReactLoading
                      type="bars"
                      color="#2c1266"
                      height={50}
                      width={50}
                    />
                  )
                }
                text="Pontos Programa de Recompensa"
              />
              <PointsWidget
                icon={<RedemPoints />}
                points={
                  orders ?? (
                    <ReactLoading
                      type="bars"
                      color="#2c1266"
                      height={50}
                      width={50}
                    />
                  )
                }
                text="Pontos Resgatados"
              />
            </div> */}
            <TableSubscribedTournaments />
            <TableNextsTournaments />
          </div>
          <div className="right-information">
            <UserInformation
              icon={<img src={ranking} alt="" />}
              name={userInfo().name}
              image={
                user?.avatar === undefined
                  ? ProfilePicture
                  : `${process.env.REACT_APP_API_URL}/media/avatar/${user?.avatar}`
              }
              id={user?.id}
              points={123}
              columns={columnsInfoUser}
              data={rankingLimiter}
            />
            {myrankings.length > 3 && (
              <button
              type="button"
              onClick={() => history.push('/ranking')}
              >
                Ver mais
              </button>
            )}
          </div>
        </div>
        {/* <div className="row-without-border">
          <SectionTitle
            customClass="blog-section-title"
            title="Últimas Notícias"
          />
          <BlogList blogList={blogs} />
        </div> */}

        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onCloseModal={() => setIsModalOpen(false)}
            classStatus="success"
            title="Video"
          >
            <div className="video-background">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/FhBnW7bZHEE"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Modal>
        )}
      </HomeStyle>
    </BasePage>
  );
};

export default Home;
