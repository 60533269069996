import CreateForm from "./CreateForm";
import { Container, Content, FakeOverlay, FakeLoader } from "./styles";

const QuickTournaments = () => {
  return (
    <Container>
      <FakeOverlay>
        <FakeLoader />
      </FakeOverlay>


      <Content>
        <CreateForm />
      </Content>
    </Container>
  );
};

export default QuickTournaments;
